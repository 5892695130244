// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {dw5mbQLd2: {hover: true}};

const serializationHash = "framer-er1ta"

const variantClassNames = {dw5mbQLd2: "framer-v-qfeeup"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, BgPvZqcfS: link ?? props.BgPvZqcfS, NDmPeADNm: title ?? props.NDmPeADNm ?? "Primary Button"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, NDmPeADNm, BgPvZqcfS, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "dw5mbQLd2", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={BgPvZqcfS}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-qfeeup", className, classNames)} framer-17rns7i`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"dw5mbQLd2"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-9065ca1f-1dfb-4011-af90-7c0570697ffc, rgb(229, 77, 46))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{"dw5mbQLd2-hover": {backgroundColor: "rgb(255, 85, 51)"}}} {...addPropertyOverrides({"dw5mbQLd2-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7U2F0b3NoaS1ib2xk", "--framer-font-family": "\"Satoshi\", \"Satoshi Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-line-height": "1em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-f31f1ce1-16c9-4281-87ba-360912934036, rgb(255, 255, 255)))"}}>Become Member</motion.p></React.Fragment>} className={"framer-10lzf1"} fonts={["FS;Satoshi-bold"]} layoutDependency={layoutDependency} layoutId={"logSpfOfTmP_G55PkR"} style={{"--extracted-r6o4lv": "var(--token-f31f1ce1-16c9-4281-87ba-360912934036, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={NDmPeADNm} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-er1ta.framer-17rns7i, .framer-er1ta .framer-17rns7i { display: block; }", ".framer-er1ta.framer-qfeeup { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: hidden; padding: 16px 17px 16px 17px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-er1ta .framer-10lzf1 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-er1ta.framer-qfeeup { gap: 0px; } .framer-er1ta.framer-qfeeup > * { margin: 0px; margin-bottom: calc(8px / 2); margin-top: calc(8px / 2); } .framer-er1ta.framer-qfeeup > :first-child { margin-top: 0px; } .framer-er1ta.framer-qfeeup > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 146
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"tAFx5KNfD":{"layout":["auto","auto"]}}}
 * @framerVariables {"NDmPeADNm":"title","BgPvZqcfS":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerAdid6FwGT: React.ComponentType<Props> = withCSS(Component, css, "framer-er1ta") as typeof Component;
export default FramerAdid6FwGT;

FramerAdid6FwGT.displayName = "Button/Primary";

FramerAdid6FwGT.defaultProps = {height: 48, width: 146};

addPropertyControls(FramerAdid6FwGT, {NDmPeADNm: {defaultValue: "Primary Button", displayTextArea: false, title: "Title", type: ControlType.String}, BgPvZqcfS: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerAdid6FwGT, [{explicitInter: true, fonts: [{family: "Satoshi", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/LAFFD4SDUCDVQEXFPDC7C53EQ4ZELWQI/PXCT3G6LO6ICM5I3NTYENYPWJAECAWDD/GHM6WVH6MILNYOOCXHXB5GTSGNTMGXZR.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})